/**
 * Android, IOS pdf opening method
 * As navi ios sdk is not supporting pdf opening, we are using this method
 */

export const openPdf = (url, fn) => {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (window.Android) {
    if (fn) fn();
  } else if (window.webkit && window.webkit.messageHandlers.visitIosView) {
    return window.webkit.messageHandlers.visitIosView.postMessage(
      JSON.stringify({
        method: 'openPDF',
        url: url,
      })
    );
  } else if (window.ReactNativeWebView) {
    return window.ReactNativeWebView.postMessage(
      JSON.stringify({
        method: 'OPEN_PDF',
        url: url,
      })
    );
  } else if (/android/i.test(userAgent)) {
    console.log('Android');
    const anchor = document.createElement('a');
    anchor.setAttribute('href', url);
    anchor.setAttribute('target', '_blank');
    anchor.click();
    return;
  } else {
    window.open(url, '_blank');
  }
};
