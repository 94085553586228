export function login(
  phoneNumber,
  countryCode,
  encData,
  history,
  resend,
  queryParams
) {
  return {
    type: 'LOGIN',
    payload: {
      phoneNumber,
      countryCode,
      encData,
      history,
      resend,
      queryParams,
    },
  };
}

export function logout(history) {
  return {
    type: 'LOGOUT',
    payload: {
      history,
    },
  };
}

export function loginMagicLink(magicLink, history, tab) {
  return {
    type: 'SSO_MAGIC_CODE',
    payload: {
      magicLink,
      history,
      tab,
    },
  };
}

export function loginPhonepe(grantToken, history, queryParams) {
  return {
    type: 'SSO_PHONEPE',
    payload: {
      grantToken,
      history,
      queryParams,
    },
  };
}

export function loginSso(encrypted, clientId, history) {
  return {
    type: 'SSO_LOGIN',
    payload: {
      encrypted,
      clientId,
      history,
    },
  };
}

export function loginWithToken(authorization, queryParams, history) {
  return {
    type: 'LOGIN_WITH_TOKEN',
    payload: {
      authorization,
      queryParams,
      history,
    },
  };
}

export function verifyOtp(phoneNumber, otp, history, queryParams) {
  return {
    type: 'VERIFY_OTP',
    payload: {
      phoneNumber,
      otp,
      history,
      queryParams,
    },
  };
}

export function submitUserDetails(name, dob, gender, history) {
  return {
    type: 'SUBMIT_USER_INFO',
    payload: {
      name,
      dob,
      gender,
      history,
    },
  };
}

export function updateLoginState(state) {
  return {
    type: 'UPDATE_LOGIN_STATE',
    payload: {
      state,
    },
  };
}

export function setFitcoinsBalance(balance) {
  return {
    type: 'SET_FITCOIN_BALANCE',
    payload: {
      balance,
    },
  };
}

export function addChatMessage(chatMessage) {
  return {
    type: 'ADD_CHAT_MESSAGE',
    payload: {
      chatMessage,
    },
  };
}

export function setChatInfo(chatInfo) {
  return {
    type: 'SET_CHAT_INFO',
    payload: {
      ...chatInfo,
    },
  };
}

export function setChatMessages(chatMessages) {
  console.log(chatMessages);
  return {
    type: 'SET_CHAT_MESSAGES',
    payload: {
      chatMessages,
    },
  };
}

export function resetChat() {
  return {
    type: 'RESET_CHAT',
  };
}

export function getConsultationSummary() {
  return {
    type: 'GET_CONSULTATION_SUMMARY',
  };
}

export function cancelConsultationBooking(requestId, history) {
  return {
    type: 'CANCEL_CONSULTATION_BOOKING',
    payload: {
      requestId,
      history,
    },
  };
}

export function rescheduleConsultationBooking(requestId, doctorName, history) {
  return {
    type: 'RESCHEDULE_CONSULTATION_BOOKING',
    payload: {
      requestId,
      doctorName,
      history,
    },
  };
}

export function showError(errorTitle, errorDesc) {
  return {
    type: 'SHOW_ERROR',
    payload: {
      hasError: true,
      errorTitle,
      errorDesc,
    },
  };
}

export function hideError() {
  return {
    type: 'HIDE_ERROR',
    payload: null,
  };
}

export function setSearchText(text) {
  return {
    type: 'SET_SEARCH_TEXT',
    payload: text,
  };
}

export function setDoctorTyping(typing, doctorId) {
  return {
    type: 'SET_DOCTOR_TYPING',
    payload: {
      typing,
      doctorId,
    },
  };
}

export function setAppConfig(config) {
  return {
    type: 'SET_APP_CONFIG',
    payload: {
      config,
    },
  };
}

export function addBotChatMessage(chatMessage) {
  return {
    type: 'ADD_BOT_CHAT_MESSAGE',
    payload: {
      chatMessage,
    },
  };
}

export function setBotChatInfo(chatInfo) {
  return {
    type: 'SET_BOT_CHAT_INFO',
    payload: {
      ...chatInfo,
    },
  };
}

export function setBotChatMessages(chatMessages) {
  console.log(chatMessages);
  return {
    type: 'SET_BOT_CHAT_MESSAGES',
    payload: {
      chatMessages,
    },
  };
}

export function resetBotChat() {
  return {
    type: 'RESET_BOT_CHAT',
  };
}

export function startVideoCall(payload) {
  return {
    type: 'START_VIDEO_CALL',
    payload,
  };
}

export function stopVideoCall() {
  return {
    type: 'STOP_VIDEO_CALL',
  };
}

export function setVideoFeedbackInfo(info) {
  return {
    type: 'SET_VIDEO_FEEDBACK_INFO',
    payload: info,
  };
}

export function setCurrentState(obj) {
  return {
    type: 'SET_CURRENT_STATE',
    payload: {
      currentState: obj.currentState,
      currentLink: obj.currentLink,
    },
  };
}

export function clearAxiosCache() {
  return {
    type: 'REMOVE_AXIOS_CACHE',
  };
}
