import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BalanceBadge } from './Fitcoin';
import { BoldText, LinkText } from './Text';
import { PrimaryButton } from './Buttons';

const HeaderContainer = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 0.625rem;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled(BoldText)`
  font-size: 1.125rem;
`;

const HeaderButton = styled(PrimaryButton)`
  background: none;
  padding: 0.25rem 1rem;
  border: 1px solid #ff5732;
`;

const ButtonText = styled(LinkText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const RewardsHeader = ({ balance, history }) => (
  <HeaderContainer>
    <HeaderTitleContainer>
      <BalanceBadge balance={balance} />
    </HeaderTitleContainer>
    <HeaderButton onTap={() => history.push('/rewards/history')}>
      <ButtonText>My purchases</ButtonText>
    </HeaderButton>
  </HeaderContainer>
);

RewardsHeader.propTypes = {
  balance: PropTypes.string.isRequired,
};

export default RewardsHeader;
