import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import LoadingComponent from '../../components/common/LoadingComponent';
import { Text } from '../../components/common/Text';
import { FlyInTransition } from '../../components/transitions/transitions';
import arrowBack from '../../images/arrow_back.svg';
import { getAllPodcasts } from '../../services/health-content';
import Modal from '../common/Modal';
import Player from './Player';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 0.5rem;
  z-index: 10;
  padding: 0px 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f3f6;
`;

const HeaderText = styled(Text)`
  margin-left: 0.625rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #464548;
`;

const BodyContainer = styled.div`
  flex: 1;
  flex-shrink: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.5rem;
  margin-top: -9px;
`;

const HeaderNew = styled.div`
  padding: 30px 01px 0px 0px;
  display: flex;
  background: white;
  align-self: stretch;
  align-items: center;
`;

const SectionHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #413958;
  padding-left: 20px;
`;

const HoverContent = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  padding: 16px 13px;
  bottom: 0px;
  left: 0px;
  border-radius: 0px 0px 18px 18px;
  width: 200px;
  color: #ffffff;
`;

const CardTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #ffffff;
`;

const CardDescription = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #e6e6e6;
  margin-top: 2px;
`;

const ModalContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: white;
  overflow: hidden;
`;

class PodcastsHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      allPodcasts: [],
      showPlayerModal: false,
      selectedAlbum: null,
    };
  }

  componentDidMount() {
    this.getAllPodcasts();
    if (
      typeof window.Android !== 'undefined' &&
      window.Android &&
      window.Android.podcastPage
    ) {
      return window.Android.podcastPage();
    }
    if (window.ReactNativeWebView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'podcastPage',
        })
      );
    }
  }

  getAllPodcasts() {
    getAllPodcasts(this.props.authToken)
      .then((res) => {
        console.log(res, 'podcasts_data');
        this.setState({ allPodcasts: res.cards });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  onAlbumClick = (album) => {
    if (album.showAudioPlayer) {
      this.setState({ selectedAlbum: album, showPlayerModal: true });
    } else {
      //it is a album container
      console.log(album, 'here it is');
      this.props.history.push('/health-content/sub-podcasts', {
        albumId: album.albumId,
      });
    }
  };

  hideModal = () => {
    console.log('clicked');
    this.setState({ showPlayerModal: false });
  };

  render() {
    const { allPodcasts, showPlayerModal, selectedAlbum, loading } = this.state;
    if (loading) {
      return <LoadingComponent />;
    }
    const renderPodcastCards = (cards) => {
      return cards.map((card) => {
        const albums = card.albums;
        return (
          <div style={{ marginTop: '25px' }}>
            <SectionHeader>{card.title}</SectionHeader>
            {albums && albums.length > 0 && (
              <div className="flex overflow-x-auto justify-start mt-12px pl-16px">
                {albums.map((album) => {
                  return (
                    <div
                      style={{ position: 'relative' }}
                      onClick={() => this.onAlbumClick(album)}
                    >
                      <img
                        src={album.imageUrl}
                        style={{
                          width: '200px',
                          height: '200px',
                          marginRight: '220px',
                          borderRadius: '18px',
                        }}
                      />
                      <HoverContent>
                        <CardTitle>{album.albumTitle}</CardTitle>
                        <CardDescription>{album.coachName}</CardDescription>
                      </HoverContent>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      });
    };

    return (
      <OuterContainer>
        <HeaderContainer>
          <HeaderNew>
            <img
              src={arrowBack}
              onClick={this.props.history.goBack}
              style={{ width: '36px' }}
            />
            <HeaderText>Meditation</HeaderText>
          </HeaderNew>
        </HeaderContainer>
        <BodyContainer>
          <div>{renderPodcastCards(allPodcasts)}</div>
          {/* <div style={{ marginTop: '25px' }}>
            {renderPodcastCards(personalGrowthAlbums)}
          </div>
          <div style={{ marginTop: '25px' }}>
            {renderPodcastCards(workAlbums)}
          </div> */}
        </BodyContainer>
        {showPlayerModal && (
          <Modal>
            <ModalContainer>
              <FlyInTransition
                in={showPlayerModal}
                mountOnEnter
                unmountOnExit
                appear
              >
                <Player
                  album={selectedAlbum}
                  authToken={this.props.authToken}
                  hideModal={this.hideModal}
                />
              </FlyInTransition>
            </ModalContainer>
          </Modal>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(PodcastsHome);
