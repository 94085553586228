/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FaFileDownload } from 'react-icons/fa';
import styled from 'styled-components';

import { BoldText, Text, SemiBoldText } from '../../components/common/Text';
import { newInsuredApi, getCOI } from '../../services';
import LoadingComponent from '../../components/common/LoadingComponent';

import { PrimaryButton } from '../../components/common/Buttons';

import { setPlaceholderImageForNode } from '../../utils/placeholder';
import { fontSize, openLinkInNewTab } from '../../utils';
import OpdPolicyCard from '../../components/policy/OpdPolicyCard';
import IpdPolicyCard from '../../components/policy/IpdPolicyCard';

import { logEvent } from '../../utils/logEvent';
import { openPdf } from '../../utils/openPdf';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  background: white;
`;

const Body = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: 2rem;
  overflow-x: hidden;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.2 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  // align-self: flex-start;
  text-align: center;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  margin-left: 0.625rem;
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0 18px;
  align-self: stretch;
  align-items: center;
  justify-content: center;
`;

const HeaderText = styled(BoldText)`
  font-size: 12px;
  text-align: center;
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: #f4f4f4;
  border: 0.5px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 4px;
`;

const TabItem = styled.div`
  background: ${(props) => (props.active ? '#ffffff' : '#F4F4F4')};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 8px 20px;
`;

const TabText = styled(Text)`
  font-size: 12px;
  font-weight: ${(props) => (props.active ? 700 : 500)};
  color: #413958;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  overflow-x: auto;
`;

const BenefitsContainer = styled.div`
  margin-top: 16px;
  padding: 0 24px;
`;

const BenefitList = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-top: 12px;
  padding: 18px 14px;
  &::last-child {
    margin-bottom: 0;
  }
`;

const BenefitsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 14px;
  margin-bottom: 16px;
`;

const BenefitItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const CoverageItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const BenefitIcon = styled.img`
  width: 24px;
  margin-right: 12px;
`;

const setPlaceholderImageForVerticals = () => {
  Array.from(document.getElementsByClassName('profile-image')).forEach(
    (ele, idx) => {
      console.log({ idx, ele });
      setPlaceholderImageForNode(ele);
    }
  );
};

class InsureTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      insured: false,
      opdPolicies: [],
      ipdPolicies: [],
      claims: [],
      opdWalletDetails: [],
      policyDetails: {},
      policySavings: {},
      reimbursementRequests: null,
      coiUrl: null,
      activeTabIndex: 0,
      activeTab: '',
    };

    const { search } = props.location;
    if (search) {
      const params = new URLSearchParams(search);
      if (params.get('auth')) {
        this.authToken = `JWT ${params.get('auth')}`;
      }
    }

    console.log({ props });
  }

  componentDidUpdate() {
    setPlaceholderImageForVerticals();
  }

  continueVisit = (redirectTo) => {
    switch (redirectTo) {
      case 'web': {
        break;
      }
      case 'playStore': {
        openLinkInNewTab(
          'https://play.google.com/store/apps/details?id=com.getvisitapp.window.android&hl=en'
        );
        break;
      }
      case 'appStore': {
        openLinkInNewTab(
          'https://play.google.com/store/apps/details?id=com.getvisitapp.window.android&hl=en'
        );
        break;
      }
      default: {
        break;
      }
    }
  };

  downloadCOI = () => {
    logEvent('OPD Download COI');
    this.setState({ startDownload: true });
    const { authToken } = this.props;
    if (this.props.sponsorId === 90) {
      this.setState({
        coiUrl:
          'https://api.getvisitapp.com/apiv3/static-resources/AkzoNobel-OPD-Benefit-Policy.pdf',
        startDownload: false,
      });
      if (
        typeof window.Android !== 'undefined' &&
        typeof window.Android.downloadFile !== 'undefined'
      ) {
        return window.Android.downloadFile(
          'https://api.getvisitapp.com/apiv3/static-resources/AkzoNobel-OPD-Benefit-Policy.pdf'
        );
      } else {
        document.querySelector(`#resendCoiLink`).click();
      }
      if (window.webkit) {
        if (window.webkit.messageHandlers) {
          if (window.webkit.messageHandlers.reactNative) {
            window.webkit.messageHandlers.reactNative.postMessage(
              JSON.stringify({
                method: 'downloadCoi',
                coiUrl:
                  'https://api.getvisitapp.com/apiv3/static-resources/AkzoNobel-OPD-Benefit-Policy.pdf',
              })
            );
          }
        }
      }
      return;
    }
    getCOI(authToken)
      .then((result) => {
        const { coiUrl } = result;
        this.setState({ coiUrl, startDownload: false });
        logEvent('OPD Download COI', {
          link: coiUrl,
        });
        if (
          typeof window.Android !== 'undefined' &&
          typeof window.Android.downloadFile !== 'undefined'
        ) {
          return window.Android.downloadFile(coiUrl);
        } else {
          document.querySelector(`#resendCoiLink`).click();
        }
        if (window.webkit) {
          if (window.webkit.messageHandlers) {
            if (window.webkit.messageHandlers.reactNative) {
              window.webkit.messageHandlers.reactNative.postMessage(
                JSON.stringify({
                  method: 'downloadCoi',
                  coiUrl,
                })
              );
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchData = () => {
    const { authToken } = this.props;
    newInsuredApi(authToken)
      .then((result) => {
        console.log(result);
        const opdPolicies = result.opdPolicies;
        const ipdPolicies = result.ipdPolicies;
        const claims = result.claims;
        const opdWalletDetails = result.opdWalletDetails;
        this.setState({
          opdPolicies,
          ipdPolicies,
          claims,
          opdWalletDetails,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
    // insuredApi(this.props.authToken)
    //   .then(result => {
    //     const insured = result.insured;
    //     console.log(JSON.stringify(result));
    //     let policyDetails;
    //     let policySavings = {};
    //     if (result.policyDetails && result.policySavings) {
    //       policyDetails = result.policyDetails;
    //       policySavings = result.policySavings;
    //     }
    //     this.setState({
    //       policyDetails,
    //       policySavings,
    //       reimbursementRequests: result.reimbursementRequests,
    //     });
    //     if (!policyDetails) {
    //       if (showSubscription) {
    //         window.location.assign(
    //           `${config.websiteBaseUrl}buy-subscription.html?auth=${
    //             authToken.split(' ')[1]
    //           }`
    //         );
    //       } else {
    //         window.location.assign(
    //           `${config.websiteBaseUrl}buy-opd.html?auth=${
    //             authToken.split(' ')[1]
    //           }`
    //         );
    //       }
    //       history.replace('/home');
    //     } else {
    //       if (!policyDetails.detailsFilledAt) {
    //         // Policy details have not been filled yet
    //         // Pass the auth token to the URL
    //         const authQueryParam = authToken.split(' ')[1];
    //         window.location.assign(
    //           `${config.opdPurchaseUrl}?auth=${authQueryParam}`
    //         );
    //       } else {
    //         this.setState({ loading: false });
    //       }
    //     }
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   })
    //   .finally(() => {
    //     // this.setState({ loading: false });
    //   });
  };

  componentDidMount = () => {
    if (this.props.loginState !== 'LOGGED_IN' || !this.props.authToken) {
      this.props.history.push('/');
    } else {
      this.fetchData();
    }
    logEvent('Insure Tab');
  };

  downloadFile = (url) => {
    function fn() {
      window.open(url, '_blank');
    }
    openPdf(url, fn);
  };

  render() {
    const {
      activeTabIndex,
      ipdPolicies,
      opdPolicies,
      claims,
      activeTab,
      opdWalletDetails,
    } = this.state;
    const HeaderItems = () => {
      let header = (
        <Header>
          <HeaderText>Your Policy</HeaderText>
        </Header>
      );
      return header;
    };

    const HeaderComponent = (
      <HeaderContainer bottomMargin={this.state.policyDetails}>
        {HeaderItems()}
      </HeaderContainer>
    );

    const renderTabs = () => {
      const tabs = [];
      if (ipdPolicies.length > 0) {
        tabs.push('IPD Policy');
      }
      if (opdPolicies.length > 0) {
        logEvent('OPD Insure Tab');
        tabs.push('OP Benefits');
      }
      // if (claims.length > 0) {
      //   logEvent('Insure Tab Claims');

      //   tabs.push('Claims');
      // }
      if (tabs.length > 0 && !this.state.activeTab) {
        this.setState({
          activeTab: tabs[0],
        });
      }
      return (
        <TabContainer>
          {tabs.map((ele, idx) => (
            <TabItem
              active={activeTabIndex === idx}
              onClick={() => {
                this.setState({
                  activeTab: ele,
                  activeTabIndex: idx,
                });
              }}
            >
              <TabText active={activeTabIndex === idx}>{ele}</TabText>
            </TabItem>
          ))}
        </TabContainer>
      );
    };

    const renderOpdPolicyCard = (ele, idx) => {
      return <OpdPolicyCard key={idx} policyDetails={ele} />;
    };

    const renderIpdPolicyCard = (ele, idx) => {
      let insurerLogo = null;
      let tpaLogo = null;
      if (ipdPolicies[0].insurer) {
        insurerLogo = ipdPolicies[0].insurer.logo;
      }
      if (
        ipdPolicies[0].serviceProviders &&
        ipdPolicies[0].serviceProviders.length > 0
      ) {
        tpaLogo = ipdPolicies[0].serviceProviders[0].logo;
      }
      ele = {
        ...ele,
        insurerLogo,
        tpaLogo,
      };
      return (
        <IpdPolicyCard
          key={idx}
          policyDetails={ele}
          onTap={(url) => {
            this.downloadFile(url);
          }}
        />
      );
    };

    const renderOpdBenefits = (
      <BenefitsContainer>
        <SemiBoldText color="#413958">Know Your Benefits</SemiBoldText>
        <BenefitList>
          {opdPolicies[0] &&
            opdPolicies[0].benefitsCoverage.benefits.map((ele) => (
              <BenefitItem>
                <BenefitIcon src={ele.icon} />
                <div>
                  <SemiBoldText fontSize="12px" color="#413958">
                    {ele.topText} {ele.middleText}
                  </SemiBoldText>
                  <Text fontSize="12px" color="#413958">
                    {ele.bottomText}
                  </Text>
                </div>
              </BenefitItem>
            ))}
        </BenefitList>
      </BenefitsContainer>
    );

    const renderOpdWalletDetails = () => {
      return (
        opdWalletDetails &&
        opdWalletDetails.length > 0 && (
          <BenefitsContainer>
            <SemiBoldText color="#413958">
              Outpatient Wallet Balances
            </SemiBoldText>
            {opdWalletDetails.map((ele, idx) => (
              <BenefitList>
                <BenefitsHeader>
                  <BoldText fontSize="14px" lineHeight="18px" color="#413958">
                    {ele.label}
                  </BoldText>
                  <div>
                    <Text as="span" fontSize="12px" color="#666666">
                      Current Balance:&nbsp;
                    </Text>
                    <BoldText
                      as="span"
                      fontSize="16px"
                      lineHeight="18px"
                      color="#413958"
                    >
                      ₹ {ele.availableBalanceText}
                    </BoldText>
                  </div>
                </BenefitsHeader>
                <Text margin="0 0 14px 0" fontSize="12px" color="#413958">
                  {ele.description}
                </Text>
                {ele.servicesCovered.length > 0 && (
                  <SemiBoldText
                    margin="0 0 14px 0"
                    fontSize="14px"
                    color="#413958"
                  >
                    Coverages
                  </SemiBoldText>
                )}
                {ele.servicesCovered.map((ele) => (
                  <CoverageItem>
                    <BenefitIcon src={ele.icon} />
                    <Text fontSize="12px" color="#413958">
                      {ele.label}
                    </Text>
                  </CoverageItem>
                ))}
              </BenefitList>
            ))}
          </BenefitsContainer>
        )
      );
    };

    const RenderActiveTabData = ({ tab }) => {
      switch (tab) {
        case 'OPD Policy':
          return (
            <>
              <ScrollContainer>
                {opdPolicies.length > 0 &&
                  opdPolicies[0].eCards.map(renderOpdPolicyCard)}
              </ScrollContainer>
              {renderOpdWalletDetails()}
              {opdPolicies[0] &&
                opdPolicies[0].benefitsCoverage.benefits[0] &&
                renderOpdBenefits}
            </>
          );
        case 'OP Benefits':
          return (
            <>
              <ScrollContainer>
                {opdPolicies.length > 0 &&
                  opdPolicies[0].eCards.map(renderOpdPolicyCard)}
              </ScrollContainer>
              {renderOpdWalletDetails()}
              {opdPolicies[0] &&
                opdPolicies[0].benefitsCoverage.benefits[0] &&
                renderOpdBenefits}
            </>
          );
        case 'IPD Policy':
          return (
            <div>
              <ScrollContainer>
                {ipdPolicies.length > 0 &&
                  ipdPolicies[0].policyUsers.map(renderIpdPolicyCard)}
              </ScrollContainer>
            </div>
          );
        default:
          return <div></div>;
      }
    };
    return !this.state.loading ? (
      <OuterContainer>
        <BodyContainer>
          <Body>
            {HeaderComponent}
            {renderTabs()}
            {activeTab ? <RenderActiveTabData tab={activeTab} /> : ''}
          </Body>
        </BodyContainer>
        {this.state.insured ? null : false}
        {/* <GradientPrimaryButton onTap={this.onPurchase}>
                <ButtonText>Go Cashless! Starting Rs. 1299/year</ButtonText>
              </GradientPrimaryButton> */}
      </OuterContainer>
    ) : (
      <LoadingComponent />
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  loginState: state.app.loginState,
  profileImage: state.user.profileImage,
  sponsorId: state.user.sponsorId,
  showSubscription: state.app.config.showSubscription,
  showOPD: state.app.config.showOPD,
  insured: state.app.config.insured,
  subscribed: state.app.config.subscribed,
});

export default connect(mapStateToProps)(InsureTab);
