import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import history from './utils/history';

import OnboardingStart from './containers/onboarding/OnboardingStart';
import OnboardingCorporate from './containers/onboarding/OnboardingCorporate';
import OnboardingVerifyOtp from './containers/onboarding/OnboardingVerifyOtp';
import OneLastStep from './containers/onboarding/OneLastStep';
import SubscriptionActivated from './containers/onboarding/SubscriptionActivated';
import CorporateBenefitsActivated from './containers/onboarding/CorporateBenefitsActivated';
import HomeTabContainer from './containers/home/HomeTabContainer';
import SelectUserType from './containers/onboarding/SelectUserType';
import MealLogNavigator from './containers/meal-log/MealLogNavigator';
import WhatsNotCovered from './containers/policy/WhatsNotCovered';
import RewardsNavigator from './containers/rewards/RewardsNavigator';
import HraNavigator from './containers/hra/HraNavigator';
import PhonepePaymentProcessing from './containers/common/PhonepePaymentProcessing';
import SsoContainer from './containers/home/SsoContainer';
import AuthTokenContainer from './containers/home/AuthTokenContainer';
import OnboardingHome from './containers/onboarding/OnboardingHome';
import { RegularText, BoldText } from './components/common/Text';
import StyledTappable from './components/common/StyledTappable';
import ErrorModal from './containers/common/ErrorModal';
import modalIcon from './images/common/paymentFail.svg';
import { hideError, clearAxiosCache, setAppConfig } from './actions/index';
import ReferContainer from './containers/home/ReferContainer';
import { appConfig, callMetaApi } from './services';
import BotChatContainer from './containers/bot/BotChatContainer';
// import ReimbursementNavigator from './containers/reimbursement/ReimbursementNavigator';
import AccountActivationForm from './containers/common/AccountActivationForm';
import ClaimsNavigator from './containers/claims/ClaimsNavigator';
// import PharmacyNavigator from './containers/pharmacy/PharmacyNavigator';
import SupportWindow from './containers/Support';
import BuyingJourneyNavigator from './containers/buying-journey/BuyingJourneyNavigator';
import VideoCallContainer from './containers/consultation/VideoCallContainer';
// analytics
import { initializeAmplitudeClient } from './services/analytics';
import VideoCallScreen from './containers/consultation/VideoCallScreen';
import LoadingComponent from './components/common/LoadingComponent';
import ManageDependents from './containers/home/ManageDependents';
//CSS
import './App.css';
import './comman-txt.css';
import './spacing.css';
import './Calendar.css';
import { VideoCallRedirector } from './containers/consultation/VideoCallRedirector';
import CovidPackages from './containers/lab-test/CovidPackages';
import CovidHomeNavigator from './containers/covid-home/CovidHomeNavigator';
import config from './config';
import WaterLog from './containers/water-log/WaterLog';
import PhysioProgram from './containers/physiotherapy-program/PhysioProgram';
import Redirect from './containers/deep-link/Redirect';
import HealthContentNavigator from './containers/health-content/HealthContentNavigator';
import PodcastAlbumPage from './containers/health-content/PodcastAlbumPage';
import MaternityCareProgramNavigator from './containers/maternity-care-program/MaternityCareProgramNavigator';

//Example of code splitting
//Lazy loading will split the code in seperate chunk
const LabTestNavigator = lazy(() =>
  import(
    './containers/lab-test/LabTestNavigator' /* webpackChunkName: "labs-v2" */
  )
);
const ConsultationNavigator = lazy(() =>
  import(
    './containers/consultation/ConsultationNavigator' /* webpackChunkName: "consultation" */
  )
);
const ReimbursementNavigator = lazy(() =>
  import(
    './containers/reimbursement/ReimbursementNavigator' /* webpackChunkName: "reimbursement" */
  )
);

const PharmacyNavigator = lazy(() =>
  import('./containers/pharmacy/PharmacyNavigator')
);

const HospitalizationNavigator = lazy(() =>
  import('./containers/hospitalization/HospitalizationNavigator')
);

const OuterContainer = styled.div`
  display: flex;
  flex: 1 0;
  overflow: hidden;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  max-width: 400px;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
`;

const ModalIcon = styled.img`
  width: 5.7rem;
`;

const ModalText = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const ModalTitle = styled(BoldText)`
  font-size: ${() => `${1.1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin: 0.625rem 0rem;
`;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: true,
    };
    console.log({ props });

    this.amplitudeClient = initializeAmplitudeClient();
  }

  componentDidMount = () => {
    this.initializeCleverTap();
    this.hideModal();
    this.props.removeAxiosRequest();
    this.amplitudeClient.init();
    const { authToken, setAppConfig, userId, sponsorName, userInfo } =
      this.props;
    if (userId) {
      // set amplitude userProp
      this.amplitudeClient.initUser(userInfo);
      this.amplitudeConfigured = true;
      // Set GA properties
      this.gtagConfigured = true;
      window.gtag('config', process.env.REACT_APP_GA_ID, {
        user_id: userId,
        app_name: 'VISIT PWA',
        app_version: '1.0.0',
        custom_map: {
          dimension1: 'sponsor_dimension',
        },
        sponsor_dimension: sponsorName,
      });
    }
    if (authToken) {
      appConfig(authToken)
        .then((res) => {
          setAppConfig(res.config);
        })
        .catch((err) => {
          console.log(err);
        });
      callMetaApi(authToken).catch((err) => {
        console.log(err);
      });
    }
    // if (this.props.userInfo) {
    //   this.initializeFreshDesk();
    // }
  };

  componentDidUpdate() {
    const { userId, sponsorName, userInfo } = this.props;
    if (!this.gtagConfigured && userId) {
      this.gtagConfigured = true;
      window.gtag('config', process.env.REACT_APP_GA_ID, {
        user_id: userId,
        app_name: 'VISIT PWA',
        app_version: '1.0.0',
        custom_map: {
          dimension1: 'sponsor_dimension',
        },
        sponsor_dimension: sponsorName,
      });
    }
    if (!this.amplitudeConfigured && userId) {
      this.amplitudeConfigured = true;
      // set amplitude userProp
      this.amplitudeClient.initUser(userInfo);
    }
  }
  initializeCleverTap = () => {
    console.log(config.clevertapId); // OK
    if (window.clevertap) {
      window.clevertap.account.push({ id: config.clevertapId });
    }
  };

  // initializeFreshDesk = () => {
  //   console.log(true);
  //   const initFreshChat = () => {
  //     window.fcWidget.init({
  //       config: {
  //         cssNames: {
  //           widget: 'fc_frame_custom_widget',
  //         },
  //       },
  //       token: 'a3138f46-af50-4eca-a681-90c747950123',
  //       host: 'https://wchat.freshchat.com',
  //     });
  //     window.fcWidget.user.setProperties({
  //       name: this.props.userInfo.userName,
  //       phoneNo: this.props.userInfo.userPhone,
  //       sponsorId: this.props.userInfo.sponsorId,
  //       sponsorName: this.props.userInfo.sponsorName,
  //     });
  //   };
  //   function initialize(i, t) {
  //     var e;

  //     return i.getElementById(t)
  //       ? initFreshChat()
  //       : (((e = i.createElement('script')).id = t),
  //         (e.async = !0),
  //         (e.src = 'https://wchat.freshchat.com/js/widget.js'),
  //         (e.onload = initFreshChat),
  //         i.head.appendChild(e));
  //   }
  //   function initiateCall() {
  //     return initialize(document, 'freshchat-js-sdk');
  //   }

  //   window.addEventListener
  //     ? window.addEventListener('load', initiateCall, !1)
  //     : window.attachEvent('load', initiateCall, !1);
  // };

  hideModal = () => {
    this.props.hideError();
  };

  render() {
    return (
      <OuterContainer>
        <Router history={history}>
          <Suspense fallback={<LoadingComponent />}>
            <>
              <Route path="/" exact component={OnboardingHome} />
              <Route path="/login" component={OnboardingStart} />
              <Route path="/verify-otp" component={OnboardingVerifyOtp} />
              <Route path="/one-last-step" component={OneLastStep} />
              <Route path="/opd-activated" component={SubscriptionActivated} />
              <Route path="/select-user" component={SelectUserType} />
              <Route
                path="/corporate-onboard"
                component={OnboardingCorporate}
              />
              <Route path="/additional-info" component={WhatsNotCovered} />
              <Route
                path="/corporate-activated"
                component={CorporateBenefitsActivated}
              />
              <Route path="/refer" component={ReferContainer} />
              <Route path="/home" component={HomeTabContainer} />
              <Route path="/bot" component={BotChatContainer} />
              <Route path="/consultation" component={ConsultationNavigator} />
              <Route path="/lab-test" component={LabTestNavigator} />
              <Route path="/reimbursement" component={ReimbursementNavigator} />
              <Route path="/rewards" component={RewardsNavigator} />
              <Route
                path="/hospitalization"
                component={HospitalizationNavigator}
              />
              <Route path="/sso" component={SsoContainer} />
              <Route path="/auth-login" component={AuthTokenContainer} />
              <Route path="/logmeal" component={MealLogNavigator} />
              <Route path="/hra" component={HraNavigator} />
              <Route path="/video" component={VideoCallScreen} />
              <Route
                path="/processing-payment"
                component={PhonepePaymentProcessing}
              />
              <Route path="/claims" component={ClaimsNavigator} />
              <Route path="/pharmacy" component={PharmacyNavigator} />
              <Route path="/support" component={SupportWindow} />
              <Route
                path="/activate-account"
                component={AccountActivationForm}
              />
              <Route
                path="/buying-journey"
                component={BuyingJourneyNavigator}
              />
              <Route path="/manage-dependents" component={ManageDependents} />
              <Route path="/video-call" component={VideoCallContainer} />
              <Route path="/c" component={VideoCallRedirector} />
              <Route path="/covid-packages" component={CovidPackages} />
              <Route path="/covid-home" component={CovidHomeNavigator} />
              <Route path="/covid-swiggy" component={CovidHomeNavigator} />
              <Route path="/covid-gartner" component={CovidHomeNavigator} />
              <Route path="/water-details" component={WaterLog} />
              <Route path="/physio-program" component={PhysioProgram} />
              <Route
                path="/maternity-care"
                component={MaternityCareProgramNavigator}
              />
              <Route path="/redirect" component={Redirect} />
              <Route
                path="/health-content"
                component={HealthContentNavigator}
              />
            </>
          </Suspense>
        </Router>
        {this.props.appError.hasError && (
          <ErrorModal>
            <ModalContainer>
              <ModalBody>
                <CloseContainer onTap={this.hideModal}>
                  <FaTimes />
                </CloseContainer>
                <ModalIcon src={modalIcon} />
                <ModalTitle>{this.props.appError.errorTitle}</ModalTitle>
                <ModalText>{this.props.appError.errorDesc}</ModalText>
              </ModalBody>
            </ModalContainer>
          </ErrorModal>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  userId: state.user.userId,
  sponsorId: state.user.sponsorId,
  sponsorName: state.user.sponsorName,
  appError: state.app.appError,
  userInfo: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  hideError: () => {
    dispatch(hideError());
  },
  removeAxiosRequest: () => {
    dispatch(clearAxiosCache());
  },
  setAppConfig: (config) => {
    dispatch(setAppConfig(config));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
