import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import MaternityCareProgram from './MaternityCareProgram';
import MaternityUploadPrescription from './MaternityCareDocVerification';

const MaternityCareProgramNavigator = ({ loginState, authToken, history }) => {
  if (loginState !== 'LOGGED_IN' || !authToken) {
    history.push('/');
    return false;
  }
 return (
   <Switch>
     <Route path="/maternity-care" exact component={MaternityCareProgram} />
     <Route
       path="/maternity-care/doc-verification"
       exact
       component={MaternityUploadPrescription}
     />
   </Switch>
 );
}


const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(MaternityCareProgramNavigator);