/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { FaRegFilePdf } from 'react-icons/fa';

import TappableCard from '../common/TappableCard';
import StyledTappable from '../common/StyledTappable';
import { Text, BoldText } from '../common/Text';

import videoCallIcon from '../../images/consultations/videocall.svg';
import clinicIcon from '../../images/consultations/clinic.svg';
import mapsIcon from '../../images/consultations/gmap.png';
import calendarIcon from '../../images/consultations/calendar-icon.svg';
import { fontSize } from '../../utils';
import { openPdf } from '../../utils/openPdf';

const OuterContainer = styled(TappableCard)`
  padding: 0.875rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
`;

const TitleContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: space-between;
`;

const TitleInnerContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-shrink: 0;
  align-items: center;
`;

const TitleIcon = styled.img`
  height: 0.5rem;
  margin-right: 0.5rem;
`;

const TitleText = styled(Text)`
  color: #808080;
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const TimeText = styled(Text)`
  color: #b3b3b3;
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const BodyContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: auto;
  margin-top: 0.875rem;
  margin-bottom: 0.5rem;
`;

const BodyIcon = styled.img`
  width: 3.125rem;
  border-radius: 1.6rem;
  margin-right: 0.875rem;
`;

const BodyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const BodyTextTitle = styled(BoldText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const BodyTextSubtitle = styled(Text)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #4d4d4d;
`;

const ActionContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-shrink: 0;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`;

const RequestStatusContainer = styled.div`
  margin-top: 0.625rem;
  padding: 0.625rem 0.75rem;
  padding-bottom: 0;
  display: flex;
  flex: 1;
  flex-shrink: 0;
  align-items: center;
  border-top: ${(props) => (props.border ? '1px solid #7D7D7D' : 'none')};
`;

const expand = keyframes`
  from {
    box-shadow: 0 0 0 0rem rgba(94, 200, 108, 0.40);
  }
  to {
    box-shadow: 0 0 0 0.25rem rgba(94, 200, 108, 0.40);
  }
`;

const RequestStatusIcon = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: #5ec86c;
  margin-right: 0.75rem;
  animation: ${expand} 2s linear infinite;
`;

const RequestStatusTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
`;

const RequestStatusText = styled(Text)`
  flex: 1;
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #4d4d4d;
`;

const RequestStatusDoctorText = styled(Text)`
  font-size: ${() => fontSize(0.875)};
  color: #4c4c4c;
`;

const AttachmentContainer = styled.div`
  background: #f5f5f5;
  border-radius: 0.375rem;
  display: flex;
  flex-shrink: 0;
  padding: 0.625rem 0.75rem;
  margin-right: 0.25rem;
  align-items: center;
`;

const AttachmentImgIcon = styled.div`
  color: #ff5732;
  width: 3.5rem;
  height: 3.5rem;
  background-image: ${(props) => `url(${props.src})`};
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 0.75rem;
`;

const AttachmentPdfIcon = styled(FaRegFilePdf)`
  color: #ff5732;
  margin-right: 0.75rem;
`;

const AttachmentTextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const AttachmentName = styled(BoldText)`
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #4d4d4d;
`;

const AttachmentDate = styled(Text)`
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #7e7e7e;
`;

const SlotIcon = styled.img`
  width: 1.5rem;
  margin-right: 0.75rem;
`;

const ConsultationCard = ({
  className,
  onTap,
  labelIcon,
  consultationLabel,
  dateLabel,
  doctorName,
  consultationIssues,
  doctorImage,
  sponsorId,
  actions,
}) => {
  const renderAction = (ele, idx) => {
    const {
      type,
      label,
      doctorName,
      date,
      timing,
      fileName,
      fileUrl,
      fileType,
      uploadedOn,
      lat,
      lng,
    } = ele;
    switch (type) {
      case 'pending-r':
        return (
          <RequestStatusContainer border key={idx}>
            <RequestStatusIcon />
            <RequestStatusText>{label}</RequestStatusText>
          </RequestStatusContainer>
        );
      case 'pending-p':
        return (
          <RequestStatusContainer border key={idx}>
            <RequestStatusIcon />
            <RequestStatusTextContainer>
              <RequestStatusText>{label}</RequestStatusText>
              <RequestStatusDoctorText>{doctorName}</RequestStatusDoctorText>
            </RequestStatusTextContainer>
          </RequestStatusContainer>
        );
      case 'upload':
        return (
          <StyledTappable
            key={idx}
            stopPropagation
            onTap={() => {
              function fn() {
                document.querySelector(`#image-anchor-${idx}`).click();
              }
              openPdf(fileUrl, fn);
            }}
          >
            <AttachmentContainer>
              {fileType === 'image' ? (
                <AttachmentImgIcon src={fileUrl} />
              ) : (
                <AttachmentPdfIcon />
              )}
              <AttachmentTextContainer>
                <AttachmentName>{`${fileName.substr(0, 15)}${
                  fileName.length > 15 ? '...' : ''
                }`}</AttachmentName>
                <AttachmentDate>{uploadedOn}</AttachmentDate>
              </AttachmentTextContainer>
            </AttachmentContainer>
            <a
              href={fileUrl}
              target="_blank"
              rel="noopener noreferrer"
              id={`image-anchor-${idx}`}
              style={{ display: 'none' }}
            />
          </StyledTappable>
        );
      case 'slot':
        return (
          <StyledTappable key={idx} stopPropagation>
            <AttachmentContainer>
              <SlotIcon src={calendarIcon} />
              <AttachmentTextContainer>
                <AttachmentName>{date}</AttachmentName>
                <AttachmentDate>{timing}</AttachmentDate>
              </AttachmentTextContainer>
            </AttachmentContainer>
          </StyledTappable>
        );
      case 'location':
        return (
          <StyledTappable
            key={idx}
            stopPropagation
            onTap={() => {
              document
                .querySelector(
                  `#doctor-address-${lat.replace(/\./g, '')}-${lng.replace(
                    /\./g,
                    ''
                  )}`
                )
                .click();
            }}
          >
            <AttachmentContainer>
              <SlotIcon src={mapsIcon} />
              <AttachmentTextContainer>
                <AttachmentName>{label}</AttachmentName>
                <AttachmentDate>Get Directions</AttachmentDate>
              </AttachmentTextContainer>
            </AttachmentContainer>
            <a
              href={`https://maps.mapmyindia.com/navigation?places=current_location;${lat},${lng},${label}`}
              target="_blank"
              rel="noopener noreferrer"
              id={`doctor-address-${lat.replace(/\./g, '')}-${lng.replace(
                /\./g,
                ''
              )}`}
              style={{ display: 'none' }}
            />
          </StyledTappable>
        );
      default:
        return false;
    }
  };
  return (
    <OuterContainer className={className} onTap={onTap}>
      <TitleContainer>
        <TitleInnerContainer>
          <TitleIcon src={labelIcon === 'video' ? videoCallIcon : clinicIcon} />
          <TitleText>{consultationLabel}</TitleText>
        </TitleInnerContainer>
        <TimeText>{dateLabel}</TimeText>
      </TitleContainer>
      <BodyContainer>
        <BodyIcon className="doctor-image" src={doctorImage} />
        <BodyTextContainer>
          <BodyTextTitle>{doctorName}</BodyTextTitle>
          <BodyTextSubtitle>{consultationIssues}</BodyTextSubtitle>
        </BodyTextContainer>
      </BodyContainer>
      <ActionContainer>{actions && actions.map(renderAction)}</ActionContainer>
    </OuterContainer>
  );
};

const mapStateToProps = (state) => ({
  sponsorId: state.user.sponsorId,
});

ConsultationCard.propTypes = {
  onTap: PropTypes.func,
  consultationType: PropTypes.oneOf([
    'con-online',
    'con-offline',
    'con-request',
    'con-pending',
  ]).isRequired,
  labelIcon: PropTypes.string.isRequired,
  consultationLabel: PropTypes.string.isRequired,
  dateLabel: PropTypes.string.isRequired,
  doctorName: PropTypes.string.isRequired,
  consultationIssues: PropTypes.string.isRequired,
  doctorImage: PropTypes.string.isRequired,
  actions: PropTypes.array,
};

export default connect(mapStateToProps)(ConsultationCard);
